.initialisation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.initialisation .header {
    position: relative;
    left: 12.5%;
    color: #ffffff;
    font-family: "Bahnschrift";
    font-weight: 100;
}

.initialisation .init-flexbox {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding-right: 17px;
    box-sizing: content-box;
}

.initialisation .init-flexbox .table,
.initialisation .init-flexbox .pit {
    position: relative;
    left: 12.5%;
    width: 66%;
    margin: 0.75%;
    background-color: #1f2126;
    border: 1.5px #5f606a solid;
    display: flex;
    flex-direction: column;
    font-family: "Bahnschrift";
    font-weight: 100;
    color: #000000;
}

.initialisation .init-flexbox .table .header,
.initialisation .init-flexbox .pit .header {
    display: flex;
    width: 100%;
    left: 0;
    border-bottom: 1.5px #5f606a solid;
}

.initialisation .init-flexbox .table .header .header-title,
.initialisation .init-flexbox .pit .header .header-title {
    flex-grow: 12;
    font-size: 1.25rem;
}

.initialisation .init-flexbox .table .header .header-title h2,
.initialisation .init-flexbox .pit .header .header-title h2 {
    margin: 15px;
    font-weight: 100;
}

.initialisation .init-flexbox .table .header .header-ip,
.initialisation .init-flexbox .pit .header .header-ip {
    flex-grow: 1;
    display: flex;
}

.initialisation .init-flexbox .table .header .header-ip .ipadd,
.initialisation .init-flexbox .pit .header .header-ip .ipadd {
    margin-right: 15px;
    color: #c5c5c5;
    font-weight: 100;
}

.initialisation .init-flexbox .table .header .header-ip .ip,
.initialisation .init-flexbox .pit .header .header-ip .ip {
    font-weight: 100;
}

.initialisation .init-flexbox .table .body,
.initialisation .init-flexbox .pit .body {
    display: flex;
}

.initialisation .init-flexbox .table .body .info,
.initialisation .init-flexbox .pit .body .info {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.initialisation .init-flexbox .table .body .info .info-child,
.initialisation .init-flexbox .pit .body .info .info-child {
    display: flex;
    color: #ffffff;
    font-weight: 100;
    padding-left: 5%;
}

.initialisation .init-flexbox .table .body .info .info-child .title,
.initialisation .init-flexbox .pit .body .info .info-child .title {
    color: #c5c5c5;
    margin-right: 15px;
}

.initialisation .init-flexbox .table .body .info .info-child .data,
.initialisation .init-flexbox .pit .body .info .info-child .data {
    font-weight: 100;
}

.initialisation .init-flexbox .table .body .link,
.initialisation .init-flexbox .pit .body .link {
    flex-grow: 1;
    background-color: #303238;
    margin: 1%;
    border: 1px #5f606a solid;
    padding: 4.2%;
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    width: 180px;
}

button:disabled,
button[disabled] {
    color: #666666 !important;
}