/*======================================================== DARK LAYOUT =========================================================*/
.form-control.flatpickr-input {
    cursor: default;
    background: #444444;
    font-family: inherit;
    border: none;
    padding: 0.8em;
    color: white;
    text-align: center;
}

/*
.form-control.flatpickr-input~input {
    background-color: #fff;
} */

.flatpickr-calendar {
    transform: scale(0.9);
    transform-origin: left;
}

/* 
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #7d8fa1;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month,
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
    fill: #fff;
    color: #fff;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month {
    background: #7d8fa1;
}

.flatpickr-calendar .flatpickr-months .flatpickr-month select:hover {
    background: #fff;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper .numInput {
    color: #fff;
} */

.flatpickr-calendar .flatpickr-weekdays {
    width: calc(100% + 1px);
}

/* .flatpickr-calendar .flatpickr-weekdays,
.flatpickr-calendar .flatpickr-weekday {
    background: #7d8fa1;
    color: #fff;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected:hover {
    background: #7d8fa1;
    border-color: #7d8fa1;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day:hover,
.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
    background: #ededed;
    border-color: #ededed;
} */

/* .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.inRange {
    box-shadow: -5px 0 0 #ededed, 5px 0 0 #ededed;
} */

/* .flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.flatpickr-disabled {
    color: #00f3ff;
} */
.flatpickr-months .flatpickr-month {
    background: inherit !important;
    margin-top: -9px;
}

.flatpickr-calendar .flatpickr-days .dayContainer {
    background: #303030;
}

.flatpickr-calendar {
    /* background: #444 !important; */
    width: fit-content;
    margin: 0;
    padding: 0.8em;
    background: #1e1e1e;
}

.flatpickr-weekday {
    background: #1e1e1e !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: rgba(0, 0, 0, 0) !important;
    color: white !important;
}

.flatpickr-calendar .flatpickr-days .dayContainer .flatpickr-day.selected {
    border-color: #00f3ff;
    background: #585858;
}

.flatpickr-calendar,
span.flatpickr-weekday {
    background: #1e1e1e !important;
}

[dir=ltr] .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
[dir=ltr] .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
[dir=ltr] .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 #ff5a5f;
    box-shadow: -10px 0 0 #00f3ff;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
    color: #444 !important;
}

.flatpickr-monthDropdown-month {
    background: #444 !important;
}


.apexcharts-series.apexcharts-heatmap-series rect {
    cursor: pointer;
}

.apexcharts-series.apexcharts-heatmap-series rect[val="0"] {
    stroke: rgba(0, 0, 0, 0);
    cursor: inherit
}

.flatpickr-day.inRange {
    border-radius: 0;
    -webkit-box-shadow: -5px 0 0 #585858, 5px 0 0 #585858;
    box-shadow: -5px 0 0 #585858, 5px 0 0 #585858;
    background: #585858;
    border-color: #585858;
}