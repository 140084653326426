/* montserrat-300 */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Montserrat-Light.ttf') format('woff2');
}
/* montserrat-regular */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Montserrat-Regular.ttf') format('woff2');
}
/* montserrat-500 */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Montserrat-Medium.ttf') format('woff2');
}
/* montserrat-600 */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('woff2');
}

.material-icons {
     font-family: 'Material Icons';
     font-weight: normal;
     font-style: normal;
     font-size: 24px;
     line-height: 1;
     letter-spacing: normal;
     text-transform: none;
     display: inline-block;
     white-space: nowrap;
     word-wrap: normal;
     direction: ltr;
     -webkit-font-feature-settings: 'liga';
     font-feature-settings: 'liga';
     -webkit-font-smoothing: antialiased;
 }

 .checklist-icon {
     margin-top: 5px;
 }

 body {
     /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
         "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
         sans-serif !important; */
     font-family: "Montserrat", Helvetica, Arial, serif !important;
     /* -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale; */
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.45;
 }

 body,
 html,
 #root {
     height: 100%;
     width: 100%;
     margin: 0;
     padding: 0;
     background-color: #2a2a2a;
 }

.MuiPickersLayout-contentWrapper {
    color-scheme: dark;
}

 .apexcharts-canvas text {
     fill: #fff !important;
 }

 .apexcharts-legend-series .apexcharts-legend-text {
     color: #fff !important;
 }

.rbc-month-view {
    border: 1px solid #424242 !important;
}

.rbc-month-row {
    border-top: 1px solid #424242 !important;
}

.rbc-header {
    border-left: 1px solid #424242 !important;
    border-bottom: none !important;
    background-color: grey;
    padding: 0.35rem !important;
}

.rbc-today {
   background: none !important;
}

.rbc-off-range-bg {
    background: #383838 !important;
}

.rbc-day-bg {
    border-left: 1px solid #424242 !important;
}

.rbc-date-cell {
    margin: 5px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rbc-now span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background: #00a1ff !important;
    font-weight: bold !important;
    border-radius: 50% !important;
    width: 15px !important;
    height: 15px !important;
    padding: 15px !important;
}

.rbc-selected-cell {
    background: #616364 !important;
}

 #root {
     display: flex;
     flex: 1 1;
     flex-wrap: nowrap;
 }

 .gamespie-chart-text {
     fill: #fff;
     -moz-transform: translateY(0.25em);
     -ms-transform: translateY(0.25em);
     -webkit-transform: translateY(0.25em);
     transform: translateY(0.25em);
     pointer-events: 'none';
 }

 .gamespie-chart-number {
     font-size: 0.5em;
     line-height: 1;
     text-anchor: middle;
     -moz-transform: translateY(-0.25em);
     -ms-transform: translateY(-0.25em);
     -webkit-transform: translateY(-0.25em);
     transform: translateY(-0.25em);
     pointer-events: 'none';
 }

 .gamespie-chart-label {
     font-size: 0.14em;
     text-transform: uppercase;
     text-anchor: middle;
     -moz-transform: translateY(0.7em);
     -ms-transform: translateY(0.7em);
     -webkit-transform: translateY(0.7em);
     transform: translateY(0.7em);
     pointer-events: 'none';
 }

 @keyframes gradient-wave {
     0% {
         background-position: 50% 0;
     }

     50% {
         background-position: 50% 100%;
     }

     100% {
         background-position: 50% 0;
     }
 }

 @keyframes gradient-wave-inverse {
     0% {
         background-position: 50% 100%;
     }

     50% {
         background-position: 100% 50%;
     }

     100% {
         background-position: 50% 100%;
     }
 }

 @keyframes skew {
     0% {
         transform: skewX(0deg);
     }

     30% {
         transform: skewX(10deg);

     }

     60% {
         transform: skewX(-10deg);
     }

     100% {
         transform: skewX(0deg);
     }
 }